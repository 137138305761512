import LogoutButton from '@/components/LogoutButton/LogoutButton';

function ProfileTabProfile() {
  return (
    <>
      <LogoutButton />
    </>
  );
}

export default ProfileTabProfile;
