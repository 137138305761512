var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"TafrpeNlU2jP6_OhL5Jb8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a52da015df4fc2ecdc0c92da84e04f02@o4505824725172224.ingest.sentry.io/4505824732774400',
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV
  });
}
