"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.camelCase = void 0;
function camelCase(input) {
    var output = input.toLowerCase();
    return output.split(' ').reduce(function (s, c) {
        return s + (c.charAt(0).toUpperCase() + c.slice(1));
    });
}
exports.camelCase = camelCase;
