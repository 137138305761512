"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidEmail = void 0;
/**
 * Checks if a string is formatted like an email.
 * @param email The email to check.
 * @returns Whether the string is an email.
 */
function isValidEmail(email) {
    var results = email.match(/^\S+@\S+\.\S+$/);
    return results !== null;
}
exports.isValidEmail = isValidEmail;
